<script lang="ts" setup>
const props = defineProps<{
  theme: any
}>()

const variablesColor = computed(() => {
  if (
    props.theme &&
    props.theme.background &&
    props.theme.primary &&
    props.theme.secondary
  ) {
    const backgroundColor = useColor(props.theme.background)
    const primary = useColor(props.theme.primary)
    const secondary = useColor(props.theme.secondary)
    return {
      '--primary': primary.getHex(),
      '--secondary': secondary.getHex(),
      '--background': backgroundColor.getHex(),
    }
  }
})
</script>
<template>
  <div
    :style="variablesColor"
    :class="[`bg-[--secondary] align-bottom`]"
  >
    <div class="h-10 w-full rounded-tl-full bg-gray-800 px-12"></div>
    <div class="w-full bg-gray-800 px-5">
      <div
        class="mx-auto flex h-12 w-full max-w-6xl flex-wrap bg-gray-800 py-0 text-left text-sm text-white"
      >
        <a
          href="https://www.eo.nl/algemene-voorwaarden"
          class="text-current no-underline hover:underline"
          rel="noreferrer"
          >Algemene voorwaarden</a
        >
        <span class="mx-2 md:mx-6">&#183;</span>
        <a
          href="https://www.eo.nl/privacy"
          class="text-current no-underline hover:underline"
          >Privacy</a
        >
        <span class="mx-2 md:mx-6">&#183;</span>
        <a
          href="https://over.eo.nl/contact"
          class="text-current no-underline hover:underline"
          >Contact</a
        >
        <span class="mx-2 md:mx-6">&#183;</span>
        <a
          href="https://over.eo.nl/werken-bij"
          class="text-current no-underline hover:underline"
          >Werken bij de EO</a
        >
        <span class="mx-2 md:mx-6">&#183;</span>
        <a
          href="https://over.eo.nl/pers"
          class="text-current no-underline hover:underline"
          >Pers</a
        >
        <span class="mx-2 md:mx-6">&#183;</span>
        <a
          href="#cookie-consent"
          class="ccm_message_link text-current no-underline hover:underline"
          rel="noreferrer"
          >Cookie-instellingen</a
        >
      </div>
    </div>
  </div>
</template>
